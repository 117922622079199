.crm-layout .logo {
  display: flex;
  justify-content: space-between;
  height: 32px;
  margin: 16px;
  color: #fff;
  font-weight: bold;
  line-height: 32px;
  padding: 0 12px;
  a {
    color: #fff;
  }
}

.site-layout .site-layout-background {
  background: #fff;
  padding: 24px;
  margin-top: 24px;
  min-height: 300px;
}

.ant-descriptions-item-content {
  .ant-table {
    margin-left: 0 !important;
  }
}
tr.ant-table-expanded-row .ant-descriptions-view table {
  width: 100%;
}

.ant-form-item-label > label {
  white-space: normal !important;
}

.list-note {
  padding: 16px;
  border: 1px solid #e8e8e8;
  background-color: #efefef;
  margin: 12px 0;
}
